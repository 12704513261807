import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Box, useTheme } from '@mui/material'
import {
  useCurrentUser,
  usePricesProducts,
} from '@talentinc/gatsby-theme-ecom/components/NewCheckout/queries'
import { usePlanCodes } from '@talentinc/gatsby-theme-ecom/components/NewCheckout/usePlanCodes'
import { usePurchasePageEvent } from '@talentinc/gatsby-theme-ecom/hooks/useCheckoutEvent'
import useDiscountToken from '@talentinc/gatsby-theme-ecom/hooks/useDiscountToken'
import { useFETelemetry } from '@talentinc/gatsby-theme-ecom/hooks/useTelemetry'
import { CheckoutForm } from '@talentinc/gatsby-theme-ecom/components/NewCheckout/CheckoutForm'
import { Discount } from '@talentinc/gatsby-theme-ecom/components/NewCheckout/Discount'
import { Toaster } from 'react-hot-toast'

export const CheckoutTemplate = () => {
  const theme = useTheme()
  const planCodes = usePlanCodes()
  const urlDiscountToken = useDiscountToken()

  usePurchasePageEvent({
    planCodes: planCodes,
    discountToken: urlDiscountToken,
  })

  const [discountToken, setDiscountToken] = useState(urlDiscountToken || '')

  const telemetry = useFETelemetry()

  const pricesProducts = usePricesProducts({ planCodes, discountToken })
  const currentUser = useCurrentUser()

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <Box
        pt={3}
        pb={10}
        sx={{
          height: '100%',
          minHeight: '60vh',
          maxWidth: 1086,
          width: '95%',
          margin: 'auto',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignContent: 'flex-start',

          [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
          },
        }}
      >
        <Toaster position="top-right" reverseOrder={false} />
        <Box
          display="flex"
          gap="20px"
          style={{ width: '100%' }}
          sx={{
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column-reverse',
            },
          }}
        >
          <Box
            sx={{
              width: '655px',
              [theme.breakpoints.down('lg')]: {
                width: '100%',
              },
            }}
          >
            <CheckoutForm
              discountToken={discountToken}
              planCodes={planCodes}
              pricesProducts={pricesProducts.data}
              isLoading={pricesProducts.isLoading || currentUser.isLoading}
              user={currentUser.data}
            />
          </Box>

          <Discount
            value={discountToken}
            onChange={(value) => {
              telemetry.track({
                event: 'discount_apply_click',
                properties: { event_type: 'checkout_event' },
              })

              setDiscountToken(value)
            }}
            planCodes={planCodes}
            sx={{
              maxWidth: '390px',
              height: 'fit-content',

              [theme.breakpoints.down('lg')]: {
                minWidth: '350px',
                maxWidth: '100%',
              },

              [theme.breakpoints.down('md')]: {
                minWidth: '100%',
              },
            }}
          />
        </Box>
      </Box>
    </>
  )
}
