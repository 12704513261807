import { useQuery } from "react-query"
import * as logger from "../../utils/logging"
import * as api from "./api"

export const usePricesProducts = (args: { planCodes: string[]; discountToken?: string }) => {
  return useQuery({
    queryKey: ["pricesProducts", args.planCodes, args?.discountToken],
    queryFn: () => api.calculateProductPrices(args),
    select: ({ data }) => data,
    enabled: args.planCodes.length > 0,
    onError: (error) => logger.error("Error retrieving products:", error),
    refetchOnWindowFocus: false,
  })
}

export const useCurrentUser = () => {
  return useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const session = await api.getSession()
      return api.getUserById(session.data.lead_auth_token)
    },
    select: ({ data }) => data,
    refetchOnWindowFocus: false,
    retry: false,
  })
}
//
