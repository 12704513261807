import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  BoxProps,
  Divider,
  useTheme,
  Button,
  TextField,
  Typography,
  Skeleton,
} from '@mui/material'
import { Check } from '@mui/icons-material'
import { usePricesProducts } from '../queries'
import { CalculatePricesResponse } from '../types'
import { localizedCurrency } from '../utils'
import { filteredPlanBenefits } from './utils'
import { useBETelemetry } from '@talentinc/gatsby-theme-ecom/hooks/useTelemetry'

const DiscountFinalPrice: React.FC<{
  plans: CalculatePricesResponse | undefined
  loading?: boolean
}> = ({ plans, loading }) => {
  if (loading)
    return (
      <Box py={2}>
        <Skeleton animation="wave" variant="text" width={'100%'} height="40px" />
      </Box>
    )
  if (!plans?.planPrices) return null
  const { planPrices, totalPrice } = plans

  const firstPlan = planPrices?.[0]

  return (
    <Box
      flexDirection="column"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {firstPlan?.discountToken && (
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography
            fontFamily="tt-talent"
            style={{
              fontSize: 13,
              color: '#09143b',
              fontWeight: 600,
            }}
          >
            {firstPlan?.discountDescription}
          </Typography>
          <Typography
            fontFamily="tt-talent"
            style={{ fontSize: 13, color: '#09143b', fontWeight: 600 }}
          >
            - {localizedCurrency(firstPlan?.currencyCode, firstPlan?.discountValue)}
          </Typography>
        </Box>
      )}
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography fontFamily="tt-talent" style={{ fontSize: 19 }}>
          Total
        </Typography>
        <Box display="flex" flexDirection="row">
          <Typography
            fontFamily="tt-talent"
            style={{ fontSize: 17, color: '#09143b', fontWeight: 600 }}
          >
            {localizedCurrency(firstPlan?.currencyCode, totalPrice)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const PlanItems: React.FC<{
  plans: CalculatePricesResponse | undefined
  loading?: boolean
}> = ({ plans, loading }) => {
  if (loading)
    return (
      <Box py={2}>
        <Skeleton animation="wave" variant="text" width={'100%'} height={40} />
      </Box>
    )
  if (!plans?.planPrices) return <Typography>No plans available</Typography>

  const { planPrices } = plans

  return planPrices?.map(
    ({ planId, planName, currencyCode, price, planBenefits }) => (
      <Box
        key={planId}
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="12px"
          sx={{ marginRight: 10 }}
        >
          <Typography
            style={{
              fontSize: '19px',
              color: '#09143b',
              fontWeight: 500,
              letterSpacing: 0.1,
              lineHeight: '24px',
              fontFamily: 'tt-talent',
            }}
          >
            {planName}
          </Typography>

          {planBenefits && (
            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
              {filteredPlanBenefits(planBenefits)?.map((i) => (
                <li key={i} style={{ display: 'flex', alignItems: 'center' }}>
                  <Check
                    style={{
                      color: '#1A91F0',
                      fontSize: '20px',
                      marginRight: 7,
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: '15px',
                      letterSpacing: -0.1,
                      fontFamily: 'tt-talent',
                    }}
                  >
                    {i}
                  </Typography>
                </li>
              ))}
            </ul>
          )}
        </Box>

        <Box display="flex" flexDirection="row" gap="2px">
          <Typography fontFamily="tt-talent">
            {localizedCurrency(currencyCode, price)}
          </Typography>
        </Box>
      </Box>
    )
  )
}

type DiscountProps = {
  sx?: BoxProps['sx']
  planCodes: string[]
  value: string
  onChange: (value: string) => void
}

export function Discount(props: DiscountProps) {
  const { sx = {}, planCodes, value, onChange } = props
  const theme = useTheme()

  const [code, setCode] = useState('')
  const [showError, setShowError] = useState('')
  const telemetry = useBETelemetry() // Initialize telemetry
  const hasTrackedEvent = useRef(false) // Ref to track if the event has been sent

  const {
    data: planPrices,
    isLoading: isRequestLoading,
    isFetched: isRequestFetched,
  } = usePricesProducts({
    planCodes,
    discountToken: value,
  })

  const isLoading = isRequestLoading || !isRequestFetched

  useEffect(() => {
    if (
      planPrices &&
      planPrices.discountFound !== undefined &&
      !hasTrackedEvent.current
    ) {
      const errorMessage = planPrices.discountFound ? '' : 'Invalid code'
      setShowError(errorMessage)

      // Track the telemetry event here after the discount code validation
      telemetry.track({
        event: 'click_discount_apply',
        properties: {
          timestamp: Date.now() / 1000,
          plan_code: planCodes.join(', '),
          ...(errorMessage && { error_text: errorMessage }), // Include error_text only if there is an error
        },
      })

      hasTrackedEvent.current = true // Set the ref to true to prevent future triggers
    }
  }, [planPrices, planCodes, telemetry])

  const handleApplyClick = (e: React.FormEvent) => {
    e.preventDefault()

    if (!code || isLoading) return

    onChange(code)
    setCode('')
    hasTrackedEvent.current = false // Reset the ref when a new discount code is applied
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#fff',
          borderRadius: '6px',
          ...sx,
        }}
      >
        <Box
          component="header"
          style={{
            height: 44,
            backgroundColor: '#1E2532',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '6px 6px 0 0',
            padding: '0 0 0 20px',
          }}
        >
          <Typography
            fontFamily="tt-talent"
            style={{
              textTransform: 'uppercase',
              color: '#fff',
              fontSize: '12px',
              fontWeight: 500,
              letterSpacing: 2,
            }}
          >
            Order Summary
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap="20px"
          style={{ padding: '20px 20px 5px 20px' }}
        >
          <PlanItems plans={planPrices} loading={isLoading} />
          <Divider style={{ paddingTop: 0 }} />
          <Box component="footer" style={{ padding: '0px 0px 20px 0px' }}>
            <DiscountFinalPrice plans={planPrices} loading={isLoading} />

            <form
              onSubmit={handleApplyClick}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginTop: '20px',
                gap: 10,
              }}
            >
              <TextField
                size="small"
                variant="standard"
                placeholder="Enter discount code"
                error={!!showError}
                helperText={showError}
                style={{
                  flex: 1,
                  height: '42px',
                  border: '0px solid transparent',
                  borderColor: 'transparent',
                  backgroundColor: 'rgb(246, 247, 250)',
                  borderRadius: '6px',
                }}
                inputProps={{
                  style: {
                    padding: '9px 15px',
                    fontFamily: "'tt-talent'",
                  },
                }}
                value={code}
                onChange={(e) => {
                  setCode(e.target.value)
                  setShowError('')
                }}
              />

              <Button
                disabled={isLoading}
                type="submit"
                variant="outlined"
                style={{
                  fontFamily: 'tt-talent',
                  borderRadius: 4,
                  margin: 0,
                  height: '42px',
                  fontSize: 18,
                  backgroundColor: 'transparent',
                  border: `1px solid ${theme.colors.blue[50]}`,
                }}
              >
                Apply
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
