import { AdyenCheckout, ApplePayButtonType, Dropin, GooglePayConfiguration } from "@adyen/adyen-web/auto"
import "@adyen/adyen-web/styles/adyen.css"
import { ChevronLeft } from '@mui/icons-material'
import { Box, Button, useTheme } from '@mui/material'
import ComplianceNote from '@talentinc/gatsby-theme-ecom/components/ComplianceNote/ComplianceNote'
import BlueDotLoader from '@talentinc/gatsby-theme-ecom/components/Loaders/BlueDotLoader'
import ComplianceNoteProvider, {
  useComplianceNote,
} from '@talentinc/gatsby-theme-ecom/hooks/useComplianceNote'
import useSiteMetadata from '@talentinc/gatsby-theme-ecom/hooks/useSiteMetadata'
import { WidgetTRefs } from '@talentinc/gatsby-theme-ecom/types/widget'
import { useFormik } from 'formik'
import { navigate } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import useLocation from 'react-use/lib/useLocation'
import { makeStyles } from 'tss-react/mui'
import * as Yup from 'yup'
import useBrand from '../../hooks/useBrand'
import { useBETelemetry } from '../../hooks/useTelemetry'
import useUser from '../../hooks/useUser'
import * as logger from '../../utils/logging'
import { ButtonPayOverTime, affirmCheckout, affirmOpen } from './ButtonPayOverTime'
import { Checkbox } from './Checkbox'
import { TextInput } from './TextInput'
import './adyen.css'
import { createAffirmCharge, createPurchase, getAdyenCheckoutConfig, submitAdditionalPaymentDetails } from "./api"
import { CalculatePricesResponse } from './types'
import { camel2snake, getTimezone } from './utils'
import useSearchParam from 'react-use/lib/useSearchParam'

const Schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  terms: Yup.bool().oneOf([true], 'Terms of Service must be approved'),
  email: Yup.string()
    .email('A valid Email Address is required')
    .required('Email Address is required'),
})

const SchemaForSomeoneElse = Schema.concat(
  Yup.object().shape({
    otherFirstName: Yup.string().required('First name is required'),
    otherLastName: Yup.string(),
    terms: Yup.bool().oneOf([true], 'Terms of Service must be approved'),
    otherEmail: Yup.string()
      .email('A valid Email Address is required')
      .required('Email Address is required')
      .test({
        name: 'otherEmailMustBeDifferent',
        exclusive: false,
        params: {},
        message: "This email must be different the buyer's address",
        test: function (value) {
          return value != this.parent.email
        },
      }),
    otherNote: Yup.string(),
  })
)

type Props = {
  discountToken: string
  planCodes: string[]
  pricesProducts?: CalculatePricesResponse
  isLoading: boolean
  user?: any
}

export const CheckoutForm = (props: Props) => {
  const { pathname } = useLocation()
  const { flagshipProduct, name: brandName } = useBrand()

  const { planCodes, discountToken, pricesProducts, isLoading, user } = props

  const adyenCheckoutRef = useRef<string>('adyenCheckoutRef')
  const purchaseId = useRef<string>()
  const orderId = useRef<string>()
  const visitorId = useRef<string>()
  const discountTokenRef = useRef<string>()
  const { sessionID } = useUser()

  const isUpsell = useSearchParam("type") === "upsold"

  useEffect(() => {
    if (sessionID && !visitorId.current) {
      visitorId.current = sessionID
    }
  }, [sessionID])

  const siteMetadata = useSiteMetadata()

  const telemetry = useBETelemetry()

  const [isForSomeoneElse, setIsForSomeoneElse] = useState(false)
  const isForSomeoneElseRef = useRef<boolean>(false)
  const [dropin, setDropin] = useState<Dropin | null>(null)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const ComplianceNoticeSingle = () => {
    const { t } = useTranslation()
    const { handleComplianceModalOpen } = useComplianceNote()

    return (
      <ComplianceNote>
        <ComplianceNote.TextLink
          style={{
            textTransform: 'uppercase',
            letterSpacing: '2px',
            fontSize: 12,
            textDecoration: 'none',
          }}
          onClick={(e: any) => {
            e.stopPropagation()
            handleComplianceModalOpen(e)
          }}
        >
          {t(`${WidgetTRefs.CommonTRef}.tos`)}
        </ComplianceNote.TextLink>
      </ComplianceNote>
    )
  }

  const style = makeStyles()((theme) => ({
    purchaseForm: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end',
      marginTop: '20px',

      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        flexDirection: 'column-reverse',
      },
    },
  }))

  const theme = useTheme()
  const { classes } = style()

  useEffect(() => {
    isForSomeoneElseRef.current = isForSomeoneElse
  }, [isForSomeoneElse])

  const getPartnerToken = useCallback(() => {
    const query = new URLSearchParams(window.location.search)

    return query.get('pt') ?? ''
  }, [])

  const getRedirectURL = useCallback(() => {
    const query = new URLSearchParams(window.location.search)

    return query.get('redirect_url') ?? ''
  }, [])

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      otherFirstName: '',
      otherLastName: '',
      otherEmail: '',
      otherNote: '',
      terms: true,
    },
    validationSchema: isForSomeoneElse ? SchemaForSomeoneElse : Schema,
    onSubmit: async (values) => {
      // Workaround to get the values on Adyen's onSubmit. Accessing formik directly does not update the values
      return values
    },
  })

  const showErrorToast = (errorMessage?: string) => {
    toast.error(errorMessage || "There was an error with you purchase. Please try again or contact support")
  }

  const handleServerResponse = (response: any, component: any, formValues?: any) => {
    const { host, protocol, search } = window.location

    if (!response) {
      showErrorToast()
      return
    }

    if (response.order_id) orderId.current = response.order_id

    if (response.payment_response?.action) {
      setLoadingSubmit(false)
      component.handleAction(response.payment_response.action)
    } else {
      const responseStatus = (response.status || response.resultCode || "").toLowerCase()
      const id = response.id || response.merchantReference

      if (!id || !planCodes) {
        showErrorToast()
        return
      }

      let redirectUrl = getRedirectURL()

      if (redirectUrl) {
        redirectUrl = redirectUrl + '?bought=1'
      } else {
        redirectUrl = `/upsell?order=${orderId.current}`
        const upsellType = new URLSearchParams(search)?.get('type')
        if(upsellType) redirectUrl = redirectUrl + '&type=' + upsellType
      }

      redirectUrl = encodeURIComponent(redirectUrl)

      let newUrl = `${protocol}//${host}/purchase`

      switch (responseStatus) {
        case 'authorised':
        case 'pending':
        case 'received':
          newUrl = `${newUrl}/processing?plan=${planCodes}&purchaseId=${id}`
          if (formValues?.firstName)
            newUrl = `${newUrl}&name=${formValues?.firstName}`
          newUrl = `${newUrl}&redirectUrl=${redirectUrl}`

          // TODO: Remove this after the BE are able to restore giftee data
          if (isForSomeoneElseRef.current) {
            localStorage.setItem(
              'giftee' + orderId.current,
              JSON.stringify({
                firstName: formValues?.otherFirstName,
                lastName: formValues?.otherLastName,
                email: formValues?.otherEmail,
                note: formValues?.otherNote,
              })
            )
          }

          break
        default:
          newUrl = `${newUrl}/failed?plan=${planCodes}&purchaseId=${id}`
          if (formValues?.firstName)
            newUrl = `${newUrl}&name=${formValues?.firstName}`
      }
      window.location.href = newUrl
    }
  }

  const initDropin = useCallback(async () => {
    const adyenCheckoutConfigRaw = await getAdyenCheckoutConfig(planCodes)
    const adyenCheckoutConfig = adyenCheckoutConfigRaw.data

    const allowedStoredPaymentMethods = (adyenCheckoutConfig.paymentMethodsResponse?.storedPaymentMethods || [])
      .filter(paymentMethod => !paymentMethod.brand?.includes('google') && !paymentMethod.brand?.includes('apple'))

    const adyenCheckout = await AdyenCheckout({
      clientKey: adyenCheckoutConfig.gatewayKey,
      environment: adyenCheckoutConfig.gatewayEnv,
      locale: siteMetadata.locale,
      amount: adyenCheckoutConfig.amount,
      countryCode: adyenCheckoutConfig.countryCode,
      translations: {
        "en-US": {
          "payButton.saveDetails": "Pay",
        },
      },
      paymentMethodsResponse: {
        paymentMethods: adyenCheckoutConfig.paymentMethodsResponse.paymentMethods,
        storedPaymentMethods: allowedStoredPaymentMethods
      },
      onSubmit: async (state, dropinComponent, actions) => {
        setLoadingSubmit(true)
        // Run Formik validation on all fields before proceeding
        const errors = await formik.validateForm()
        // Collecting form data and error messages
        const errorTextArray = []

        // Collect Formik validation errors
        if (errors.firstName) errorTextArray.push('First name is required')
        if (errors.lastName) errorTextArray.push('Last name is required')
        if (errors.email) errorTextArray.push('Email Address is required')
        if (errors.terms) errorTextArray.push('Approve the terms of service')

        // If "Order for Someone Else" is checked, validate those fields too
        if (isForSomeoneElseRef.current) {
          if (errors.otherFirstName)
            errorTextArray.push("Other person's First name is required")
          if (errors.otherEmail)
            errorTextArray.push("Other person's Email Address is required")
        }

        // Additional properties for telemetry with flexible typing
        const additionalProperties: Record<string, any> = {
          entered_email: formik.values.email || '',
          entered_first_name: formik.values.firstName || '',
          entered_last_name: formik.values.lastName || '',
        }

        // If there are errors, serialize the errorTextArray and add error_text to additionalProperties
        if (errorTextArray.length > 0) {
          const errorText = JSON.stringify(errorTextArray)
          additionalProperties.error_text = errorText // Add error_text dynamically
        }

        // Tracking complete order click with additional properties
        telemetry.track({
          event: 'click_complete_order',
          properties: {
            ...additionalProperties, // Merging additional properties
            visitor_id: visitorId.current
          },
        })

        // Workaround to get the values on Adyen's onSubmit.
        // Accessing formik directly does not have the updated values as useFormik is not intended to be used this way
        const formValues = await formik.submitForm()

        if (formValues && errorTextArray.length === 0) {
          const timezone = getTimezone()

          const createPurchasePayload = {
            ...state.data,
            discountCode: discountTokenRef.current,
            planCodes: planCodes,
            customer: {
              firstName: formValues.firstName,
              lastName: formValues.lastName,
              email: formValues.email,
              ...(timezone && { timezone }),
            },
            ...(isForSomeoneElseRef.current && {
              giftee: {
                firstName: formValues.otherFirstName,
                lastName: formValues.otherLastName,
                email: formValues.otherEmail,
                note: formValues.otherNote,
              },
            }),
          }

          const pt = getPartnerToken()

          createPurchase(createPurchasePayload, pt)
            .then((response) => {
              if (!response?.data?.id) {
                // TODO: refactor these repeated error recovery lines into a fn
                actions.reject()
                dropinComponent.setStatus("ready")
                setLoadingSubmit(false)
                showErrorToast()
              }
              purchaseId.current = response.data.id
              handleServerResponse(response?.data, dropinComponent, formValues)
            })
            .catch((error) => {
              console.error('error response', error)
              const paymenMethod = dropinComponent?.activePaymentMethod
              if (paymenMethod?.props?.name === 'PayPal') {
                paymenMethod.reject({})
              }
              dropinComponent.setStatus('ready')
              showErrorToast()
              setLoadingSubmit(false)
            })
        } else {
          actions.reject()
          dropinComponent.setStatus('ready')
          setLoadingSubmit(false)
        }
      },
      onAdditionalDetails: (state, component, actions) => {
        if (!purchaseId.current) {
          actions.reject()
          component.setStatus('ready')
          showErrorToast()
        } else {
          setLoadingSubmit(true)
          submitAdditionalPaymentDetails(state.data, purchaseId.current)
            .then(async (response) => {
              try {
                const { resultCode, action, order, donationToken } = response?.data

                // Doc doesn't mention special treatment for "Refused" resultCode, but it might be a better UX to
                // close the i-frame and show users the checkout form again instead of redirecting them to the failed
                // page (i.e: the status could be achieved by clicking the cancel button)
                if (!resultCode || resultCode === 'Refused') {
                  actions.reject()
                  component.setStatus('ready')
                  return
                }

                actions.resolve({ resultCode, action, order, donationToken })

                // Workaround to get the values on Adyen's onSubmit.
                // Accessing formik directly does not have the updated values as useFormik is not intended to be used this way
                const formValues = await formik.submitForm()
                handleServerResponse(response?.data, component, formValues)
              } catch (error) {
                showErrorToast()
                actions.reject()
                component.setStatus('ready')
              }
            })
            .catch((error) => {
              console.error(error)
              showErrorToast()
              actions.reject()
              component.setStatus('ready')
            })
            .finally(() => {
              setLoadingSubmit(false)
            })
        }
      },
      onPaymentFailed: (result, dropinComponent) => {
        setLoadingSubmit(true)
        const paymenMethod = dropinComponent?.activePaymentMethod
        if (paymenMethod?.props?.name === 'PayPal') {
          paymenMethod?.reject({
            name: 'Missing required fields',
            message: 'Missing required fields',
          })
          showErrorToast("There're missing required fields!")
        }
        setLoadingSubmit(false)
      },
      onError: (error, component) => {
        console.error(error.name, error.message, error.stack, component)
      },
    })

    const dropinConfiguration = {
      disableFinalAnimation: true,
      openFirstPaymentMethod: !isUpsell,
      showStoredPaymentMethods: true,
      paymentMethodsConfiguration: {
        affirm: {
          visibility: {
            deliveryAddress: "hidden"
          }
        },
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          placeholders: {
            cardNumber: '1234 5678 9012 3456',
            expiryDate: 'MM/YY',
            securityCodeThreeDigits: '123',
            holderName: 'John Smith',
          },
          billingAddressRequired: false,
          data: {
            billingAddress: {
              country: adyenCheckoutConfig.countryCode,
            },
          },
        },
        paywithgoogle: {
          buttonType: 'plain' as GooglePayConfiguration['buttonType'],
        },
        applepay: {
          buttonType: 'plain' as ApplePayButtonType,
          buttonColor: 'black' as 'black' | 'white' | 'white-with-line' | undefined,
        },
        paypal: {
          style: {
            height: 48,
          },
        },
      },
    }

    setDropin(new Dropin(adyenCheckout, dropinConfiguration).mount(adyenCheckoutRef.current))
  }, [])

  useEffect(() => {
    discountTokenRef.current = discountToken
    if (pricesProducts && dropin?.props?.amount) {
      dropin.props.amount.value = pricesProducts.totalPrice * 100
      dropin.setStatus("loading")

      const timer = setTimeout(() => {
        dropin.setStatus("loaded")
      })

      return () => { clearTimeout(timer) }
    }
  }, [pricesProducts, discountToken, dropin?.props?.amount])

  useEffect(() => {
    if (adyenCheckoutRef.current && visitorId.current) {
      initDropin()
    }
  }, [visitorId.current])

  // [Telemetry]: Handle form blur events
  const onFieldBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      formik.handleBlur(e)

      if (!formik.getFieldMeta(e.target.name).error) {
        telemetry.track({
          event: `${camel2snake(e.target.name)}_added_to_form`,
          properties: { event_type: 'checkout_event' },
        })
      }
    },
    [formik, telemetry]
  )

  const onSubmitAffirm = useCallback(async () => {
    telemetry.track({
      event: 'affirm_radio_button_click',
      properties: { event_type: 'checkout_event' },
    })

    // has validation errors
    if (Object.keys(await formik.validateForm()).length > 0) {
      return
    }

    try {
      setLoadingSubmit(true)

      affirmCheckout({
        user: {
          name: `${formik.values.firstName} ${formik.values.lastName}`,
          email: formik.values.email,
        },
        items:
          pricesProducts?.planPrices?.map((i) => ({
            sku: i.planCode,
            unitPrice: i.price,
            displayName: i.planName,
          })) ?? [],
        currency: pricesProducts?.planPrices?.at(0)?.currencyCode || 'USD',
        total: (pricesProducts?.totalPrice ?? 0) * 100,
      })

      affirmOpen({
        onSuccess: async (args) => {
          try {
            const timezone = getTimezone()

            const pt = getPartnerToken()

            const charge = await createAffirmCharge(
              {
                planCodes,
                discountToken,
                checkoutToken: args.checkout_token,
                customer: {
                  email: formik.values.email,
                  firstName: formik.values.firstName,
                  lastName: formik.values.lastName,
                  ...(timezone && { timezone }),
                },
                ...(isForSomeoneElseRef.current && {
                  giftee: {
                    firstName: formik.values.otherFirstName,
                    lastName: formik.values.otherLastName,
                    email: formik.values.otherEmail,
                    note: formik.values.otherNote,
                  },
                }),
              },
              pt
            )

            // TODO: Remove this after the BE are able to restore giftee data
            if (isForSomeoneElseRef.current) {
              localStorage.setItem(
                'giftee' + charge.data.orderId,
                JSON.stringify({
                  firstName: formik.values.otherFirstName,
                  lastName: formik.values.otherLastName,
                  email: formik.values.otherEmail,
                  note: formik.values.otherNote,
                })
              )
            }

            const redirect_url = getRedirectURL()

            if (redirect_url) {
              const redirectURL = redirect_url + '?bought=1'
              window?.location?.replace(redirectURL)
            } else {
              navigate(`/upsell?order=${charge.data.orderId}&affirm=1`)
            }
          } catch (e) {
            toast.error('Unable to complete the purchase')
            logger.error(e)
            setLoadingSubmit(false)
          }
        },
        onFail: (...e) => {
          toast.error('Affirm checkout not completed')
          logger.error(...e)
          setLoadingSubmit(false)
        },
      })
    } catch (e) {
      logger.error(e)
      setLoadingSubmit(false)
    }
  }, [
    getRedirectURL,
    pricesProducts,
    formik,
    telemetry,
    discountToken,
    planCodes,
    getPartnerToken,
    isForSomeoneElse,
    pathname,
  ])

  const onClickGoBack = () => {
    telemetry.track({
      event: 'back_to_services_click',
      properties: { event_type: 'checkout_event' },
    })

    navigate(`/${flagshipProduct}-writing`)
  }

  useEffect(() => {
    if (user) {
      formik.setFieldValue('firstName', user.first_name, false)
      formik.setFieldValue('lastName', user.last_name, false)
      formik.setFieldValue('email', user.email, false)
    }
    // eslint-disable-next-line
  }, [user])

  const checkRequiredFieldsForSomeoneElse = useCallback(() => {
    if (formik.values.otherFirstName && formik.values.otherEmail) {
      telemetry.track({
        event: 'fill_order_for_someone_else_required_field',
        properties: {
          entered_name: formik.values.otherFirstName,
          entered_email: formik.values.otherEmail,
          plan_code: planCodes[0], // Assuming the first plan code represents the purchase
        },
      })
    }
  }, [formik.values.otherFirstName, formik.values.otherEmail, telemetry, planCodes])

  return (
    <Box position="relative">
      {(isLoading || loadingSubmit) && <BlueDotLoader variant="padded" />}
      <Box
        px={2}
        py={2}
        style={{
          borderRadius: '6px',
          background: 'white',
          padding: '16px 20px',
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" flexDirection="column">
            <Box
              style={{
                display: 'flex',
                gap: 15,
                marginBottom: 20,
                flexWrap: 'wrap',
              }}
            >
              <TextInput.Wrap>
                <TextInput
                  label="First name"
                  name="firstName"
                  onChange={formik.handleChange}
                  onBlur={onFieldBlur}
                  value={formik.values.firstName}
                  helperText={formik.errors.firstName}
                  isError
                />
                <TextInput
                  label="Last name"
                  name="lastName"
                  onChange={formik.handleChange}
                  onBlur={onFieldBlur}
                  value={formik.values.lastName}
                  helperText={formik.errors.lastName}
                  isError
                />
              </TextInput.Wrap>

              <TextInput
                label="Email address"
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={onFieldBlur}
                value={formik.values.email}
                helperText={formik.errors.email}
                isError
              />
            </Box>

            <Box mb={3}>
              <Checkbox
                id="isForSomeoneElse"
                value={isForSomeoneElse}
                onChange={() => {
                  setIsForSomeoneElse((i) => !i)

                  // Track the "Order for someone else" checkbox click
                  telemetry.track({
                    event: 'click_order_for_someone_else',
                    properties: {
                      plan_code: planCodes[0], // Assuming the first plan code represents the purchase
                    },
                  })
                }}
                label="Is this order for someone else?"
              />
            </Box>

            {isForSomeoneElse ? (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 15,
                  marginBottom: 20,
                  marginTop: 10,
                }}
              >
                <TextInput.Wrap>
                  <TextInput
                    label="First name"
                    name="otherFirstName"
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e)
                      checkRequiredFieldsForSomeoneElse()
                    }}
                    value={formik.values.otherFirstName}
                    helperText={formik.errors.otherFirstName}
                  />
                  <TextInput
                    label="Last name"
                    name="otherLastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.otherLastName}
                    helperText={formik.errors.otherLastName}
                  />
                </TextInput.Wrap>

                <TextInput.Wrap>
                  <TextInput
                    label="Email address"
                    name="otherEmail"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e)
                      checkRequiredFieldsForSomeoneElse()
                    }}
                    value={formik.values.otherEmail}
                    helperText={formik.errors.otherEmail}
                  />
                  <TextInput
                    label="Note to recipient"
                    name="otherNote"
                    onChange={formik.handleChange}
                    value={formik.values.otherNote}
                    helperText={formik.errors.otherNote}
                  />
                </TextInput.Wrap>
              </Box>
            ) : null}

            <ButtonPayOverTime disabled={loadingSubmit} style={{ marginBottom: 15 }} onClick={onSubmitAffirm} />

            <Box ref={adyenCheckoutRef} id="adyen-dropin-container" />
          </Box>
        </form>
      </Box>

      <Box mt={2}>
        <ComplianceNoteProvider>
          <Box>
            <Box display="flex" flexDirection="column" gap="1px" alignItems="flex-start" justifyItems="center">
              <Box display="flex" flexDirection="row" gap="1px" alignItems="center" justifyItems="center">
                <Checkbox
                  onChange={formik.handleChange}
                  value={formik.values.terms}
                  id="terms"
                  label={<>by placing your order, you agree to {brandName}'s </>}
                />
                <Box ml="3px">
                  <ComplianceNoticeSingle />.
                </Box>
              </Box>

              {formik.errors.terms && (
                <span style={{ color: "red", fontSize: "12px", marginLeft: "28px" }}>{formik.errors.terms}</span>
              )}
            </Box>

            <Box className={classes.purchaseForm}>
              <Button onClick={onClickGoBack} variant="text" style={{ padding: 0 }}>
                <Box
                  style={{
                    color: 'rgb(51, 63, 87)',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gridGap: 3,
                  }}
                  sx={{
                    [theme.breakpoints.down('sm')]: {
                      marginTop: '30px',
                    },
                  }}
                >
                  <ChevronLeft
                    fontSize="small"
                    style={{
                      position: "relative",
                      top: -1,
                    }}
                  />
                  <span
                    style={{
                      fontSize: 17,
                      lineHeight: 1,
                    }}
                  >
                    Back To Services
                  </span>
                </Box>
              </Button>
            </Box>
          </Box>
        </ComplianceNoteProvider>
      </Box>
    </Box>
  )
}
