import React, { ComponentProps } from 'react'
import { Box, styled } from '@mui/material'

const InputLabel = styled('label')<{ isError?: boolean;}>`
  font-family: 'tt-talent', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  letter-spacing: 0.2px;
  color: ${props => props.isError ? '#FB4458' : '#828BA2'};
  transition: color .1s ease-out;
`

const Input = styled('input')<{ isError?: boolean;}>`
  font-size: 16px;
  font-family: 'tt-talent', Helvetica, Arial, sans-serif;
  color: rgb(51, 63, 87);
  height: 42px;
  border-radius: 4px;
  background-color: #EFF2F9;
  text-indent: 5px;
  outline: none;
  border: 0;
  border-bottom: 2px solid transparent;
  border-color: ${props => props.isError && '#FB4458'};
  transition: border .2s ease-out, box-shadow .2s ease-out;

  &:hover {
    border-color: #1A91F0;
  };

  &:focus {
    outline: none;
  };
`

const HelperText = styled('p')<{ isError?: boolean;}>`
  background: ${props => props.isError ? 'transparent' : '#ff0'};
  color: ${props => props.isError ? '#FB4458' : '#202124'};
  font-family: ${props => props.isError && 'tt-talent'};
  padding: ${props => props.isError ? '0px' : '2.5px 5px'};
  width: fit-content;
  font-size: ${props => props.isError && '12px'};
  font-weight: ${props => props.isError ? 400 : 600};
  margin-top: ${props => props.isError ? '4px' : '6px'};
`

type Props = ComponentProps<'input'> & {
  label: string
  helperText?: string
  isError?: boolean
}

export const TextInput = (props: Props) => {
  const { label, helperText, isError, ...inputProps } = props

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <InputLabel isError={isError && helperText}>{label}</InputLabel>
      <Input isError={isError && helperText} {...inputProps} />
      {helperText ? <HelperText isError>{helperText}</HelperText> : null}
    </Box>
  )
}

TextInput.Wrap = styled(Box)({
  display: 'flex',
  gap: 15,
  flex: 1,
  flexWrap: 'nowrap',

  '@media (max-width: 425px)': {
    flexWrap: 'wrap',
  },
})
